import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: '关于微星' },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    meta: { title: '产品列表' },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/product/index.vue')
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    meta: { title: '产品详情' },
    component: () =>
      import(/* webpackChunkName: "productDetail" */ '../views/productDetail/index.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: { title: '搜索结果' },
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/search/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

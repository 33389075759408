import router from './router'
import store from './store'

router.beforeEach(async(to, from, next) => {
  // set page title
  document.title = to.meta.title
  const hasMenu = store.state.menuList && store.state.menuList.length > 0
  if (hasMenu) {
    next()
  } else {
    await store.dispatch('getMenuList')
    next({ ...to, replace: true })
  }
})

router.afterEach(() => {

})

import request from '@/utils/request'

export function getList(api, data) {
  return request({
    url: '/index.php/api/' + api + '/index',
    method: 'post',
    data
  })
}
export function getInfo(api, data) {
  return request({
    url: '/index.php/api/' + api + '/info',
    method: 'post',
    data
  })
}

import Vue from 'vue'
import Vuex from 'vuex'
import { getList } from '@/api/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: [],
    menuStatus: false,
    sendQueen: 0
  },
  mutations: {
    'SET_MENU': (state, data) => {
      state.menuList = data
    },
    'SET_QUEEN': (state, data) => {
      if (data) {
        state.sendQueen++
      } else {
        state.sendQueen--
      }
    },
    'SET_MENU_STATUS': (state, data) => {
      state.menuStatus = data
    }
  },
  actions: {
    async getMenuList({ commit }) {
      const res = await getList('product_category')
      commit('SET_MENU', res.data)
    },
    sendQueen({ commit }, data) {
      commit('SET_QUEEN', data)
    },
    setMenuStatus({ commit }, data) {
      commit('SET_MENU_STATUS', data)
    }
  },
  modules: {}
})

import axios from 'axios'
import { Toast } from 'vant'
import store from '../store'
// eslint-disable-next-line no-unused-vars
var loading = null
// create an axios instance
console.log(location.protocol, '=====')
const host = `/`
const service = axios.create({
  baseURL: host,
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const num = store.state.sendQueen
    console.log(num, '请求')
    if (num === 0) {
      loading = Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
    }
    store.commit('SET_QUEEN', true)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    store.commit('SET_QUEEN', false)
    const num = store.state.sendQueen
    console.log(num, '请求结束')
    if (num === 0) {
      loading.clear()
    }
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 0) {
      Toast(res.msg)
      return Promise.reject(new Error(res.msg || '网络错误，请重试！'))
    } else {
      return res
    }
  },
  error => {
    store.commit('SET_QUEEN', false)
    const num = store.state.sendQueen
    if (num === 0) {
      loading.clear()
    }
    Toast('网络错误，请重试！')
    return Promise.reject(error)
  }
)

export default service

<template>
  <div id="app" :class="{['menu-open']: menuStatus}">
    <div class="base-header">
      <div class="logo" @click="goHome" />
      <div class="search">
        <van-field
          v-model="searchVal"
          class="search-text"
          left-icon="search"
          placeholder="请输入你要找的商品"
          @keyup.enter="goSearch"
        />
      </div>
      <div class="menu-btn" @click="openMenu">
        <van-icon name="wap-nav" />
      </div>
    </div>
    <transition name="van-slide-down">
      <div v-if="menuStatus" class="base-menu">
        <van-tabs v-model="menuActive" background="none" color="#FF0000" title-inactive-color="#fff" title-active-color="#fff">
          <van-tab v-for="item in menuList" :key="item.id" :title-class="{active: $route.name == 'Product' && item.id == $route.query.cateId}" :title="item.name" :to="'/product?cateId='+item.id" />
        </van-tabs>
      </div>
    </transition>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchVal: '',
      menuActive: 0
    }
  },
  computed: {
    menuList() {
      return this.$store.state.menuList
    },
    menuStatus() {
      return this.$store.state.menuStatus
    }
  },
  methods: {
    openMenu() {
      this.$store.dispatch('setMenuStatus', !this.menuStatus)
    },
    goHome() {
      this.$router.push('/')
    },
    goSearch() {
      this.$router.push({ path: '/search', query: { searchVal: this.searchVal }})
    }
  }
}
</script>
<style lang="scss">
html,
body{
  background-color: #000000;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
p {
  padding: 0;
  margin: 0;
}
#app {
  padding-top: 40px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  &.menu-open {
    padding-top: 90px;
  }
}
.base-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #333333;
  .logo {
    width: 70px;
    padding: 0 15px;
    height: 40px;
    background-image: url('./assets/msi_logo.png');
    background-size: 90%;
    background-position: center;
  }
  .menu-btn {
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 18px;
    color: #fff;
  }
  .search {
    flex: 1;
    .search-text {
      padding: 0 5px;
      height: 24px;
      border-radius: 3px;
      background-color: #666;
      .van-field__control, .van-icon {
        color: #fff;
      }
    }
  }
}
.base-menu {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 999;
  background-color: #222222;
  .van-tab__text {
    padding: 10px 0 8px 0;
    border-bottom: 2px solid #222222;
  }
  .active {
    .van-tab__text {
      border-bottom: 2px solid #FF0000;
    }
  }
  .van-tabs__line {
    display: none;
  }
  .van-tab__text--ellipsis {
    white-space: nowrap;
  }
}
.red {
  color: red
}
</style>
